import 'styles/globals.css';
import 'styles/tailwind.css';
import 'styles/stripe.css';
import 'styles/fonts.css';
import 'styles/lexical/color-picker.css';
import 'styles/lexical/floating-toolbar.css';
import 'styles/lexical/input.css';
import 'styles/lexical/index.css';
import 'cropperjs/dist/cropper.css';

import AuthHandler from 'business/auth-handler';
import Fireworks from 'components/fireworks';
import NoSsr from 'components/no-ssr';
import ToasterProvider from 'components/toaster-provider';
import { getApps, initializeApp } from 'firebase/app';
import {
  browserLocalPersistence,
  connectAuthEmulator,
  getAuth,
} from 'firebase/auth';
import { connectDatabaseEmulator, getDatabase } from 'firebase/database';
import { fetchBolderAccessControlMatrices } from 'helpers/bolder-access-control-matrices';
import { shouldInitializeIntercom } from 'helpers/intercom';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { toJS } from 'mobx';
import { observer } from 'mobx-react-lite';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import accessControlMatrix from 'observables/access-control-matrix';
import bolderAccessControlMatrix from 'observables/bolder-access-control-matrix';
import fireworks from 'observables/fireworks';
import { getAccessControlMatrix } from 'queries/auth';
import { useEffect } from 'react';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';
import { IntercomProvider } from 'react-use-intercom';
import { isBolder, isClientUser } from 'types/roles';

const INTERCOM_APP_ID = 'eh3om1vw';

type ComponentProps = Record<string, unknown>;

interface Props {
  Component: () => React.ReactNode;
  pageProps: ComponentProps;
}

if (!process.env.NEXT_PUBLIC_ENV) {
  // eslint-disable-next-line no-console
  const log = console.log;
  // eslint-disable-next-line no-console
  console.log = function (...args) {
    log.apply(
      console,
      map(args, (arg) => toJS(arg)),
    );
  };
}

interface QueryParams {
  company_id?: string;
}

const AppRoot = ({ Component, pageProps }: Props): React.ReactNode => {
  const { query, isReady } = useRouter();
  const { company_id: companyId }: QueryParams = query;

  useEffect(() => {
    const firebaseConfig = {
      apiKey: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
      authDomain: process.env.NEXT_PUBLIC_FIREBASE_AUTH_DOMAIN,
      databaseURL: process.env.NEXT_PUBLIC_FIREBASE_DATABASE_URL,
      projectId: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
      storageBucket: process.env.NEXT_PUBLIC_FIREBASE_STORAGE_BUCKET,
      messagingSenderId: process.env.NEXT_PUBLIC_FIREBASE_MESSAGING_SENDER_ID,
      appId: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
    };

    // Initialize Firebase
    if (isEmpty(getApps())) {
      initializeApp(firebaseConfig);
    }

    const auth = getAuth();
    const db = getDatabase();

    auth.setPersistence(browserLocalPersistence);

    if (process.env.NEXT_PUBLIC_ENV) return;

    connectAuthEmulator(auth, 'http://localhost:9099', {
      disableWarnings: true,
    });

    connectDatabaseEmulator(db, 'localhost', 9000);
  }, []);

  useEffect(() => {
    if (!companyId || !isClientUser()) {
      accessControlMatrix.setIsLoading(false);
      return;
    }

    const fetchAccessControlMatrix = async () => {
      try {
        accessControlMatrix.setIsLoading(true);
        const matrix = await getAccessControlMatrix(companyId);
        accessControlMatrix.setAcms(matrix);
      } finally {
        accessControlMatrix.setIsLoading(false);
      }
    };

    fetchAccessControlMatrix();
  }, [companyId]);

  useEffect(() => {
    if (!isBolder() || bolderAccessControlMatrix.isAcmLoading !== null) return;

    fetchBolderAccessControlMatrices();
  }, []);

  useEffect(() => {
    if (!isReady) return;
  }, [companyId, isReady]);

  if (!isReady) return null;

  return (
    <>
      <Head>
        <title>{'Plateforme Bold'}</title>
      </Head>

      <NoSsr>
        <GoogleReCaptchaProvider
          useEnterprise
          reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY}
        >
          <IntercomProvider
            appId={INTERCOM_APP_ID}
            autoBoot
            shouldInitialize={shouldInitializeIntercom()}
          >
            <AuthHandler>
              <>
                <Component {...pageProps} />
                <ToasterProvider />
              </>
            </AuthHandler>
            <div id="recaptcha-container" />
          </IntercomProvider>
        </GoogleReCaptchaProvider>
      </NoSsr>

      <Script
        src={`https://maps.googleapis.com/maps/api/js?key=${process.env.NEXT_PUBLIC_GOOGLE_MAPS_API_KEY}&callback=Function.prototype`}
        async
      />

      {fireworks.isVisible && <Fireworks />}
    </>
  );
};

export default observer(AppRoot);
